<template>
  <div style="margin-right: 24px;flex:1;" class="out">
    <div class="nav">
      首页 >
      <span
        class="click"
        style="font-size: 14px"
        @click="goBack"
      >支部风采</span>
      > 党支部详情
    </div>

    <div class="line" />

    <div class="topDetail">
      <div class="left">
        <img class="img" :src="require('@/assets/main/2.png')">
        <div class="text">{{ info.pbName }}</div>
        <div v-if="info.isFindPb" class="isGood center">{{ info.pbHonorName }}</div>
      </div>
      <!-- <div class="right center">查看党组织详情></div> -->
    </div>
    <div class="address">{{ info.address }}</div>
    <div class="fourItem">
      <div class="item">
        <div class="title">党员数量</div>
        <div class="count">{{ info.pmCount }}</div>
      </div>
      <div class="item">
        <div class="title">下级党组织</div>
        <div class="count">{{ info.childCount }}</div>
      </div>
      <div class="item">
        <div class="title">专题数</div>
        <div class="count">{{ info.newsCount }}</div>
      </div>
      <div class="item">
        <div class="title">活动会议</div>
        <div class="count">{{ info.meetingCount }}</div>
      </div>
    </div>
    <div class="summary">
      <div class="title">组织概述：</div>
      <div class="content">{{ info.summary }}</div>
    </div>

    <div v-if="info.leaderData.length" class="rich2" style="margin-top:55px">
      <div class="title">
        <div class="left" />
        <div class="text">领导班子（{{ info.leaderData.length }}）</div>
      </div>
      <div class="content2">
        <div
          v-for="(item, index) in info.leaderData"
          :key="index"
          class="contentItem"
          style="padding-right: 24px"
        >
          <img class="img" :src="$beforeUrl(item.avatar)">
          <div class="name">{{ item.pmName }}</div>
          <div class="jobName">{{ item.jobName }}</div>
          <div :class="{ hide: !item.isBestGuys }" class="youXiu center">
            {{ item.honoraryTitle }}
            <div class="youXiuBg" />
          </div>
        </div>
      </div>
    </div>

    <div class="line3" />

    <div v-if="info.guysData.length" class="rich2" style="margin-top:55px">
      <div class="title">
        <div class="left" />
        <div class="text">党组成员（{{ info.guysData.length }}）</div>
      </div>
      <div class="content2">
        <div
          v-for="(item, index) in info.guysData"
          :key="index"
          class="contentItem"
          style="padding-right: 24px"
        >
          <img class="img" :src="$beforeUrl(item.avatar)">
          <div class="name">{{ item.pmName }}</div>
          <div :class="{ hide: !item.isBestGuys }" class="youXiu center">
            {{ item.honoraryTitle }}
            <div class="youXiuBg" />
          </div>
        </div>
      </div>
    </div>

    <div class="line3" />

    <div v-if="info.notes" class="rich">
      <div class="title">
        <div class="left" />
        <div class="text">组织简介</div>
      </div>
      <div class="content rich" v-html="$setContent(info.notes)" />
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      param: {
        id: ''
      }
    }
  },
  watch: {
    $route() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.param = this.$getParams()
      this.$parent.loading = true
      this.$store
        .dispatch('main/PbGetPbInfo', { id: this.param.id })
        .then((res) => {
          if (res.code === 0) {
            this.info = res.data
            this.$parent.loading = false
          }
        })
    },
    goBack() {
      this.$router.push({ path: '/GetPbDem' })
    }
  }
}
</script>

<style lang="scss" scoped>
.out{
  overflow-x: hidden;
}
.topDetail {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
    }
    .text {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .isGood {
      margin-left: 9px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
      line-height: 32px;
      width: 90px;
      height: 24px;
      border: 1px solid var(--theme);
      border-radius: 4px;
      position: relative;
      &::after {
        content: "";
        background: var(--theme);
        opacity: 0.1;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
  .right {
    margin-right: 48px;
    width: 150px;
    height: 36px;
    background: #ffffff;
    border: 1px solid var(--theme);
    border-radius: 4px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: var(--theme);
    margin-top: 5px;
  }
}
.address {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
}
.fourItem {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  .item {
    padding-top: 38px;
    padding-bottom: 38px;
    width: 224px;
    height: 148px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &::after {
      content: "";
      background: var(--theme);
      opacity: 0.1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
    }
    .count {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--theme);
    }
  }
}
.summary {
  .title {
    margin-bottom: 19px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 32px;
  }
  .content {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
  }
}
.line {
  height: 1px;
  background: #eeeeee;
  margin-top: 19px;
  margin-bottom: 20px;
}
.nav {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

  .rich2 {
    width: 100%;
     .content2 {
    display: flex;
    overflow-x: auto;
    width: 100%;
    .contentItem {
      box-sizing: content-box;
      height: 270px;
      width: 141px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      .img {
        width: 141px;
        height: 197px;
      }
      .name {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 10px;
      }
      .jobName{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 10px;
      }
      .youXiu {
        height: 24px;
        width: max-content;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
        position: relative;
        overflow: hidden;
        padding: 0 10px;
        margin-top: 10px;
        .youXiuBg {
          background: var(--theme);
          opacity: 0.1;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: -1;
        }
      }
    }
  }
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .left {
        width: 4px;
        height: 22px;
        background: var(--theme);
        margin-right: 7px;
      }
      .text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
      }
    }
  }

  .line3 {
    height: 1px;
    background: #eeeeee;
    margin-bottom: 25px;
  }

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(17, 16, 16, 0.13);
  -webkit-box-shadow: rgba(0, 0, 0, 0.9);
  box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(211, 173, 209, 0.4);
}
.hide{
  visibility: hidden;
}
</style>
